import React, { Component } from "react";
import * as API from "../../configuration/apiconfig";
import "react-datepicker/dist/react-datepicker.css";
import Datatable from "react-bs-datatable";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Swal from "sweetalert2";


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import UpdateErrorMessage from "./UpdateErrorMessage";
import CreateErrorMessage from "./CreateMessage";

class errorMessages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createModel: false,
      updateModel: false,
      singleRowData: null,
      errorMessageData: null,
      currentPage: 0,
      totalPages: "",
      totalRecords: "",
      limit: 10,
      pageSizeOption: [
        {
          title: 5,
          value: 5,
        },
        {
          title: 10,
          value: 10,
        },
        {
          title: 15,
          value: 15,
        },
        {
          title: 20,
          value: 20,
        },
        {
          title: "All",
          value: null,
        },
      ],
      header: [
        { title: "ErrorCode", prop: "code" },
        {
          title: "Message Create",
          prop: "createdAt",
          sortable: true,
          cell: (row) => {
            return (
              <>
                <p>
                  {row.createdAt
                    ? moment(row.createdAt).format("DD-MM-YYYY HH:mm")
                    : "---"}
                </p>
              </>
            );
          },
        },
        {
          title: "Error Message",
          prop: "message",
        },
        {
          title: "Action",
          prop: "",
          cell: (row) => {
            return (
              <>
                <p
                  className="view-img"
                  style={{ cursor: "pointer", marginBottom:"10px" }}
                  onClick={() => this.handleUpdate(row)}
                >
                  Update
                </p>{" "}
                <p
                  className="view-img"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.messageDeletePopup(row)}
                >
                  Delete
                </p>{" "}
              </>
            );
          },
        },
      ],
    };
  }

  componentDidMount = async () => {
    await this.getErrorMessages();
  };

  getErrorMessages = async () => {
    await API.callEndpoint("GET", "Bearer", `/api/errormesg`)
      .then((response) => {
        console.log("response.data", response?.data?.data);
        this.setState({ errorMessageData: response?.data?.data });
      })
      .catch((error) => {
        swal({
          text: "An error occured, try again!",
          icon: "info",
        });
      });
  };

  //   handleOpenImg = (row) => {
  //     console.log("row", row);
  //     this.setState({ open: true, imgData: row?.imgurl });
  //   };

  handleUpdate = (row) => {
    this.setState({ updateModel: true, singleRowData: row });
  };
  handleCreate = () => {
    this.setState({ createModel: true});
  };

  handlePageClick = ({ selected }) => {
    this.setState({ currentPage: selected });
  };

  handleRowsPerPageChange = (e) => {
    const selectedValue = e.target.value;
    const { webinarTableDatas } = this.state;

    if (webinarTableDatas.length === 0) {
      this.setState({
        limit: 5,
      });
    } else if (selectedValue === "All") {
      this.setState({
        limit: this.state.totalRecords,
      });
    } else {
      this.setState({
        limit: parseInt(selectedValue, 10),
      });
    }
  };

  hidemodal = () => {
    this.setState({ open: false });
  };

  deleteErrorMessage = (data) => {
    API.callEndpoint("POST", "Bearer", "/api/errormesg/delermsg", data)
      .then((response) => {
        try {
          this.setState({
            showSuccess: true,
            showError: false,
          });
          this.getErrorMessages();
          // setTimeout(() => {
          //   this.onHide();
          // }, 1000);
        } catch (e) {
          this.setState({
            showError: true,
          });
          //  document.getElementById("wrongmess").style.display = "block";
        }
      })
      .catch((ex) => {
        // this.setState({ showLoader: false });
        this.setState({
          showError: true,
        });
        // document.getElementById("wrongmess").style.display = "block";
      });
  };

  messageDeletePopup = async (row) => {
    await Swal.fire({
      title: "Are you sure you want delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      let data = {
        id: row?.id,
      };

      if (await this.deleteErrorMessage(data)) {
        Swal.fire("Delete", "Your Messgae has been Deleted.", "success");
      }
    });
  };

  render() {
    return (
      <>
        <section className={`usermanage`}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1 className="head text-uppercase m-0 mt-lg-4 mb-lg-4 pt-lg-3 pb-lg-2">
              Error Message
            </h1>
            <section className="admin-bundleTable-page">
              <aside className="add-bundle">
            <div className="new-call-btn" onClick={this.handleCreate}>
            Create Error Message
</div>
              </aside>
            </section>
          </div>
          <section className={`contblk`}>
            <h2 className="head text-uppercase m-0 mt-lg-4 pt-lg-3"></h2>
            <section className={`row m-0 p-0 mt-1`}>
              <aside className={`col-12 m-0 p-0`}>
                {/* <ReactPaginate
                  pageCount={this.state.totalPages}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={3}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                /> */}
                <div className="scroll-x">
                  {this.state.errorMessageData?.length > 0 &&
                  <Datatable
                    id="table-to-xls"
                    tableHeaders={this.state.header}
                    tableBody={this.state.errorMessageData}
                    // rowsPerPage={this.state.srowsPerPage}
                    labels={{
                      filterPlaceholder: "Search",
                    }}

                    // onSort={this.onSort}
                  />}
                </div>
              </aside>
            </section>
          </section>
        </section>
        {this.state.updateModel && (
          <UpdateErrorMessage
            show={this.state.updateModel}
            data={this.state.singleRowData}
            onHide={() => this.setState({ updateModel: false })}
            getErrorMessages={this?.getErrorMessages}
          />
        )}
        {this.state.createModel && (
          <CreateErrorMessage
            show={this.state.createModel}
            data={this.state.singleRowData}
            onHide={() => this.setState({ createModel: false })}
            getErrorMessages={this?.getErrorMessages}
          />
        )}
      </>
    );
  }
}

export default errorMessages;
